/* Existing styles */
.profile-container {
  position: absolute;
  top: 20px; /* Adjust the top position as needed */
  left: 20px; /* Adjust the left position as needed */
  width: 150px; /* Increased width */
  height: 150px; /* Increased height */
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-image {
  width: 200px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.link-icon {
  display: inline-block;
  padding: 8px 12px;
  background-color: #007BFF; /* Blue background */
  color: white; /* White text */
  text-decoration: none; /* No underline */
  border-radius: 4px; /* Rounded corners */
  font-family: Arial, sans-serif; /* Font styling */
  font-size: 16px; /* Text size */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.link-icon:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.text-background {
  background-image: url('images/2.jpg'); /* Add your image path here */
  background-size: cover; /* or use 100% 100% to cover the entire div */
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  padding: 50px 20px; /* Adjust padding as needed */
  text-align: center;
}

.text-background {
  width: 100vw;
  position: relative; /* Adjust if necessary depending on other layout needs */
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

.header-text h1 {
  font-size: 2.5em;
  margin: 10px 0;
  color: white; /* Change text color if needed for better visibility */
}

.header-text p {
  font-size: 1.2em;
  color: #eee; /* Change text color if needed for better visibility */
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  max-width: 1200px;
  margin: auto;
}

.logo {
  width: 100px;
}

#header {
  background: #f5f5f5;
  padding: 20px 0;
}

#header nav {
  display: flex;
  justify-content: center; /* Center the navigation */
  align-items: center;
}

#header ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

#header ul li {
  margin: 0 20px; /* Add spacing between items */
  display: flex;
  align-items: center;
}

#header ul li i {
  margin-right: 8px; /* Space between icon and text */
}

#header ul li a {
  text-decoration: none;
  color: #333;
  font-size: 16px; /* Adjust font size if needed */
}

#sidemenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header .fa-bars, #header .fa-times {
  display: none;
  cursor: pointer;
}

.header-text {
  text-align: center;
  padding: 50px 20px;
}

.header-text h1 {
  font-size: 2.5em;
  margin: 10px 0;
}

.header-text p {
  font-size: 1.2em;
  color: #666;
}

.header-text span {
  color: #ff6347;
}

#about {
  background-color: #f9f9f9;
  padding: 50px 20px;
}

.sub-title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.sub-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background: #ff6347;
  margin: 10px auto;
}

.about-col-1 {
  flex: 1;
  padding-right: 20px;
}

.about-col-2 {
  flex: 2;
}

.about-col-2 h1 {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
}

.about-col-2 p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
  background-color: #f5f5f5; /* Light background for better readability */
  padding: 15px; /* Padding for some spacing */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a lifted effect */
}


.about-col-2 ul {
  list-style: none;
  padding: 0;
}

.about-col-2 ul li {
  font-size: 1.2em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 10px;
}

.about-col-2 ul li span {
  display: inline-block;
  width: 100px;
  font-weight: bold;
}

.about-col-2 ul li span#percent {
  float: right;
  color: #ff6347;
}

.about-col-2 .tab-titles {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.about-col-2 .tab-titles p {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #f5f5f5;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.about-col-2 .tab-titles p:hover {
  background-color: #ff6347;
  color: white;
}

.about-col-2 .tab-titles p.active-link {
  background-color: #ff6347;
  color: white;
}

.about-col-2 .tab-contents {
  display: none;
  margin-top: 20px;
}

.about-col-2 .tab-contents.active-tab {
  display: block;
}

.about-col-2 .vertical-skills {
  list-style: none;
  padding: 0;
}

.about-col-2 .vertical-skills li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
  border-radius: 5px;
}

.about-col-2 .vertical-skills li span#percent {
  color: #ff6347;
}

.tab-titles {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.tab-links {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ddd;
  flex: 1;
  text-align: center;
  margin-right: 5px;
  background-color: #f5f5f5;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tab-links:hover {
  background-color: #ff6347;
  color: white;
}

.tab-links:last-child {
  margin-right: 0;
}

.active-link {
  background: #ff6347;
  color: #fff;
  border: none;
}

.tab-contents {
  display: none;
  margin-top: 20px;
}

.active-tab {
  display: block;
}

.vertical-skills {
  list-style: none;
  padding: 0;
  font-size: 1.1em;
}

.vertical-skills li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

#percent {
  color: #ff6347;
}

.services-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.services-list > div {
  flex: 1;
  margin: 20px;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.services-list > div:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.services-list i {
  font-size: 2em;
  color: #ff6347;
  margin-bottom: 10px;
}

.services-list h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
}

.services-list h3 {
  font-size: 1.2em;
  color: #666;
  margin-bottom: 20px;
}

.services-list p {
  font-size: 1em;
  color: #555;
  line-height: 1.6;
  margin-bottom: 10px;
}

.work-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.work {
  position: relative;
  flex: 1;
  margin: 20px;
}

.work img {
  width: 100%;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.work:hover .layer {
  opacity: 1;
}

.layer h3 {
  font-size: 1.5em;
  margin: 10px 0;
}

.layer p {
  text-align: center;
  padding: 0 20px;
}

.layer a {
  color: #ff6347;
  margin-top: 10px;
  font-size: 1.2em;
}

.contact-left {
  flex: 1;
  padding: 20px;
}

.contact-left p, .contact-right form input, .contact-right form textarea {
  margin-bottom: 20px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 1.5em;
  color: #333;
}

.social-icons a:hover {
  color: #ff6347;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background: #ff6347;
  text-decoration: none;
  border-radius: 5px;
}

.btn2 {
  background: #333;
}

.contact-right {
  flex: 1;
  padding: 20px;
}

.contact-right form input, .contact-right form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.copyright {
  text-align: center;
  padding: 20px 0;
  background: #f5f5f5;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .services-list, .work-list {
    flex-direction: column;
  }

  #header .fa-bars, #header .fa-times {
    display: block;
  }

  #sidemenu {
    flex-direction: column;
  }

  #sidemenu ul {
    flex-direction: column;
    align-items: center;
  }

  #sidemenu ul li {
    margin: 20px 0;
  }

  #sidemenu ul li a {
    color: #fff;
  }
}

/* New styles for Services.js content */
#services {
  background-color: #f9f9f9;
  padding: 50px 20px;
}

#services .container {
  max-width: 1200px;
  margin: auto;
}

#services .sub-title {
  font-size: 2.5em;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

#services .services-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

#services .services-list > div {
  flex: 1;
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

#services .services-list > div:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#services .services-list i {
  font-size: 2.5em;
  color: #ff6347;
  margin-bottom: 10px;
}

#services .services-list h2 {
  font-size: 1.8em;
  color: #333;
  margin-bottom: 10px;
}

#services .services-list h3 {
  font-size: 1.5em;
  color: #666;
  margin-bottom: 20px;
}

#services .services-list p {
  font-size: 1.1em;
  color: #555;
  line-height: 1.8;
  margin-bottom: 10px;
}
